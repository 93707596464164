import { ReactComponent as LogoEnquete } from "../enquete/container/logo/LogoEnquete.svg";
import { ReactComponent as LogoFlame_P } from "../enquete/container/logo/LogoFlame_P.svg";
import { ReactComponent as LogoFlame_S } from "../enquete/container/logo/LogoFlame_S.svg";
import { ReactComponent as LogoFlower } from "../enquete/container/logo/LogoFlower.svg";
import { ReactComponent as ImageEnquete } from "../enquete/container/logo/ImageEnquete.svg";
import imageEnquete from "../enquete/container/image/ImageEnquete.png";
import {MessageRSVP_P} from "../enquete/container/message/RSVP_P";
import {MessageRSVP_S} from "../enquete/container/message/RSVP_S";
import {ButtonBases_P} from "../enquete/container/button/btnImage_PC";
import {ButtonBases_S} from "../enquete/container/button/btnImage_SP";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Parallax from "react-rellax";
import { Spacer } from "../container/spacer";
import { isMobile } from "react-device-detect"
import { MoveEnquete } from "./container/move/moveEnquete";

//css
import './container/style/styleEnquete.css';
import './container/style/styleEnquete_PC.css';
import './container/style/styleEnquete_SP.css';

export const TitleEnquete = () => {

  return(
    <div>
      {isMobile ?  <Spacer size={300}/>  :  <Spacer size={600}/> }

      <div className="title">
        <div data-aos="fade-up" data-aos-offset="300" data-aos-once="true" className={isMobile ? "enqueteSub_I_S" : "enqueteSub_I_P" }>
          <ImageEnquete className={isMobile ? "enquete-Style-image_S" : "enquete-Style-image_P"} />
        </div>
        <div className={isMobile ? "enquete-Title-logo_S" : "enquete-Title-logo_P"}>
          <MoveEnquete />
        </div>
      </div>
      {isMobile ?  <Spacer size={300}/>  :  <Spacer size={600}/> }

      <div className="AllPSVP">
        <div className={isMobile ? "cardEnquete_S" : "cardEnquete_P"}>
          {isMobile ? <LogoFlame_S className="Logo_Flame_S" /> : <LogoFlame_P className="Logo_Flame_P" /> }
          <LogoFlower className={isMobile ? "Logo_Flower_S" : "Logo_Flower_P"} />
          {isMobile ? <MessageRSVP_S /> : <MessageRSVP_P /> }
          {isMobile ?  <ButtonBases_S />  :  <ButtonBases_P /> }
        </div>
      </div>
      {isMobile ?  <Spacer size={200}/>  :  <Spacer size={600}/> }
    </div>
    
  );
};
