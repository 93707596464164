import ReactVivus from 'react-vivus';
import svg from "../logo/NameData.svg";

export const MoveName = () => {
  const handleAnimationComplete = (obj:any) => {
    obj.el.classList.add("name");
  };

  return (
    <div>
      <ReactVivus
        id="NameLogo"
        option={{
          file: svg,
          type: 'oneByOne',
          animTimingFunction: 'EASE',
          duration: 280,
        }}
        callback={handleAnimationComplete}
      /> 
    </div>
  );
};
