import { isMobile } from "react-device-detect"

const MessageSentence = () => {
  return(
    <div className={isMobile ? "CardSentence_S" : "CardSentence_P"} >
      皆様いかがお過ごしでしょうか
      <br />
      このたび　結婚式を執り行うこととなりました
      <br />
      <br />
      日頃お世話になっております皆様に
      <br />
      私どもの門出をお見守りいただきたく
      <br />
      ささやかな小宴を催したく存じます
      <br />
      <br />
      誠に恐縮ではございますが
      <br />
      是非ご出席いただきたく　ご案内申し上げます
      <br />
      <br />
      <br />
      2023年　9月吉日
    </div>
  )}

    export {MessageSentence};