import ReactVivus from 'react-vivus';
import svg from "../logo/main.svg";

export const MoveProfile = () => {
  const handleProfileComplete = (obj:any) => {
    obj.el.classList.add("fill");
  };

  return (
    <div>
      <ReactVivus
        id="main"
        option={{
          file: svg,
          type: 'oneByOne',
          animTimingFunction: 'EASE',
          duration: 250,
        }}
        callback={handleProfileComplete}
      />
    </div>
  );
};
