import ReactVivus from 'react-vivus';
import svg from "../logo/LogoMessage.svg";

export const MoveMessage = () => {
  const Complete = (obj:any) => {
    obj.el.classList.add("fill");
  };

  return (
    <div>
      <ReactVivus
        id="MessageLogo"
        option={{
          file: svg,
          type: 'delayed',
          animTimingFunction: 'EASE',
          duration: 180,
        }}
        callback={Complete}
      />
    </div>
  );
};
