import { ReactComponent as NameData } from "../message/container/logo/NameData.svg";
import { ReactComponent as ImageMessage } from "../message/container/logo/ImageMessage.svg";
import MessageParts01 from "../message/container/image/MessageParts01.png";
import MessageParts02 from "../message/container/image/MessageParts02.png";
import { MessageSentence } from "./container/message/Sentence";
import { useEffect } from "react";
import { Spacer } from "../container/spacer";
import { isMobile } from "react-device-detect"
import AOS from 'aos';
import 'aos/dist/aos.css';
import { MoveMessage } from "./container/move/moveMessage";
import { MoveName } from "./container/move/moveName";

//css
import './container/styles/styleMessage_PC.css';
import './container/styles/styleMessage_SP.css';
import './container/styles/styleMessage.css';

//変化するデータの格納
type Props = {
  name: string
}

export const TitleMessage: React.FC<Props> = props => {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return(
    <div>
      {isMobile ?  <Spacer size={200}/>  :  <Spacer size={300}/> }

      <div className="title">
        <div data-aos="fade-up" data-aos-offset="300" data-aos-once="true" className={isMobile ? "messageSub_I_S" : "messageSub_I_P" }>
          <ImageMessage className={isMobile ? "message-Style-image_S" : "message-Style-image_P"} />
        </div>
        <div className={isMobile ? "message-Title-logo_S" : "message-Title-logo_P"}>
          <MoveMessage />
        </div>
      </div>

      {isMobile ?  <Spacer size={350}/>  :  <Spacer size={700}/> }

      <div className="messageAll">
        {isMobile ?  null  :  <img src={MessageParts01} className="Card01_P" alt="message01"/> }
        <img src={MessageParts02} className={isMobile ? "Card_S" : "Card02_P"} alt="message02"/>
        <MessageSentence />
        <div className={isMobile ? "CardName_S" : "CardName_P"}>
          <MoveName />
        </div>
      </div>
    </div>
  );
};
