import { ReactComponent as LogoProfile } from "../profile/container/logo/LogoProfile.svg";
import { ReactComponent as ImageProfile } from "../profile/container/logo/ImageProfile.svg";
import imageprofile from "../profile/container/image/ImageProfile.png";
import imagekazu from "../profile/container/image/kazu.png";
import imagerisa from "../profile/container/image/risa.png";
import { FileKazu } from "./container/file/fileKazu";
import { FileRisa } from "./container/file/fileRisa";
import { MessageKazu } from "./container/message/messageKazu";
import { MessageRisa } from "./container/message/messageRisa";
import { MoveProfile } from "./container/move/moveProfile";
import { KazuNameP } from "./container/move/kazu_name_P";
import { KazuNameS } from "./container/move/kazu_name_S";
import { RisaNameP } from "./container/move/risa_name_P";
import { RisaNameS } from "./container/move/risa_name_S";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Spacer } from "../container/spacer";
import Parallax from "react-rellax";
import { isMobile } from "react-device-detect";

//css
import '../profile/container/styles/styleProfile.css';
import '../profile/container/styles/styleProfile_PC.css';
import '../profile/container/styles/styleProfile_SP.css';

export const TitleProfile = () => {
  return(
    <div>
      {isMobile ?  <Spacer size={600}/>  :  <Spacer size={800}/> }
      
      <div className="title">
        <div data-aos="fade-up" data-aos-offset="300" data-aos-once="true" className={isMobile ? "profileSub_I_S" : "profileSub_I_P" }>
          <ImageProfile className={isMobile ? "profile-Style-image_S" : "profile-Style-image_P" } />
        </div>
        <div className={isMobile ? "profile-Title-logo_S" : "profile-Title-logo_P"}>
          <MoveProfile />
        </div>
      </div>

      {isMobile ?  <Spacer size={400}/>  :  <Spacer size={280}/> }

      {isMobile ?
        <div className="kazu">
          <div className="kazu_Name_S">
            <KazuNameS />
          </div>
          <img src={imagekazu} className="kazu-image_S" alt="kazu"/>
          <FileKazu />
          <MessageKazu />
        </div>
      :
        <div className="kazu">
          <div className="cardProfile_kazu">
            <h2>KAZUMASA</h2>
            <img src={imagekazu} className="kazu-image_P" alt="kazu"/>
            <div className="kazu_Name_P">
              <KazuNameP />
            </div>
            <div className="cardProfile_mini_kazu">
              <FileKazu />
            </div>
            <MessageKazu />
          </div>
        </div>
      }

      {isMobile ?  <Spacer size={1000}/>  :  <Spacer size={600}/> }

      {isMobile ?
        <div className="risa">
          <div className="risa_Name_S">
            <RisaNameS />
          </div>
          <img src={imagerisa} className="risa-image_S" alt="risa"/>
          <FileRisa />
          <MessageRisa />
        </div>
      :
        <div className="risa">
          <div className="cardProfile_risa">
            <h2>RISA</h2>
            <img src={imagerisa} className="risa-image_P" alt="risa"/>
            <div className="risa_Name_P">
              <RisaNameP />
            </div>
            <div className="cardProfile_mini_risa">
              <FileRisa />
            </div>
            <MessageRisa />
          </div>
        </div>
      }
    </div>
  )};