import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

interface ErrorModalProps {
  open: boolean;
  onClose: () => void;
  errorMessage: React.ReactNode; // Use React.ReactNode to allow JSX elements
}

export const ErrorModal: React.FC<ErrorModalProps> = ({ open, onClose, errorMessage }) => {

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <p>{errorMessage}</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
