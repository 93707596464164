import { CeremonyMap } from "../access/container/map/ceremonyMap"
import { ReceptionMap } from "../access/container/map/receptionMap"
import { DriveMap } from "../access/container/map/driveMap"
import { ReactComponent as LogoAccess } from "../access/container/logo/LogoAccess.svg";
import { ReactComponent as ImageAccess } from "../access/container/logo/ImageAccess.svg";
import imageAccess from "../access/container/image/ImageAccess.png";
import { Spacer } from "../container/spacer";
import Parallax from "react-rellax";
import { isMobile } from "react-device-detect"
import imageCeremony from "./container/image/ceremony.png";
import imageReception from "./container/image/reception.png";
import { MoveAccess } from "./container/move/moveAccess";
import AOS from 'aos';
import 'aos/dist/aos.css';

//css
import './container/style/styleAccess.css';
import './container/style/PC/styleAccess_PC.css';
import './container/style/SP/styleAccess_SP.css';
import './container/style/PC/cardstyle_PC.css';
import './container/style/SP/cardstyle_SP.css';
import './container/style/PC/cardstyle_sub_PC.css';
import './container/style/SP/cardstyle_sub_SP.css';

export const TitleAccess = () => {
  return(
    <div>
      {isMobile ?  <Spacer size={50}/>  :  <Spacer size={400}/> }

      <div className="title">
        <div data-aos="fade-up" data-aos-offset="300" data-aos-once="true" className={isMobile ? "accessSub_I_S" : "accessSub_I_P" }>
          <ImageAccess className={isMobile ? "access-Style-image_S" : "access-Style-image_P"} />
        </div>
        <div className={isMobile ? "access-Title-logo_S" : "access-Title-logo_P"}>
          <MoveAccess />
        </div>
      </div>

      {/* {isMobile ?  <Spacer size={0}/>  :  <Spacer size={0}/> } */}

      <div className="title">
        {isMobile ? <img src={imageCeremony} className="weddingMap-image_S" alt="ceremony"/> : null}
      </div>
      <CeremonyMap />
      
      {isMobile ?  <Spacer size={100}/>  :  <Spacer size={500}/> }

      <div className="title">
        {isMobile ? <img src={imageReception} className="weddingMap-image_S" alt="reception"/> : null}
      </div>
      <ReceptionMap />
      {isMobile ?  <Spacer size={100}/>  :  <Spacer size={400}/> }
      <DriveMap />
    </div>
  )};