import SwipeableViews from "react-swipeable-views"
import { autoPlay } from 'react-swipeable-views-utils';
import { MoveProfile } from "./container/move/moveProfile";
import { isMobile } from "react-device-detect";

import image01 from "../main/container/image/image-1.jpg";
import image02 from "../main/container/image/image-2.jpg";
import image03 from "../main/container/image/image-3.jpg";
//css
import './container/style/styleAccess.css';

// ↓ここで自動的にスライドするようにする
//const EnhancedSwipeableViews = autoPlay(SwipeableViews);

export const TitleMain = () => {
  return(
      <div>
        {isMobile ? 
          <div className={"title"}>
            <img src={image02} alt="image-2" className="slider-image"/>
            <div className="main_S">
              <MoveProfile />
            </div>
          </div>
        :
          <div className={"title"}>
            <img src={image01} alt="image-1" className="slider-image"/>
            <div className="main_P">
              <MoveProfile />
            </div>
          </div>
        }
      </div>
  )};