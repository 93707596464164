import React, { createContext, useState, useEffect } from "react";

type Props = {
  children: React.ReactNode;
};

type InitialState = {
  userAuth: boolean;
  setUserAuth: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AuthContext = createContext<InitialState | null>(null);

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [userAuth, setUserAuth] = useState(false);

  useEffect(() => {
    // Access the updated value of userAuth here
    console.log("userAuth value:", userAuth);
  }, [userAuth]); // Trigger the effect whenever userAuth changes

  return (
    <AuthContext.Provider value={{ userAuth, setUserAuth }}>
      {children}
    </AuthContext.Provider>
  );
};
