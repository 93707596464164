import { ReactComponent as LogoCountdown } from "../countdown/container/logo/LogoCountdown.svg";
import { ReactComponent as LogoCountdownday } from "../countdown/container/logo/CountdownDay.svg";
import { ReactComponent as ImageCountdown } from "../countdown/container/logo/ImageCountdown.svg";
import imageCountdown from "../countdown/container/image/ImageCountdown.png";
import imageTimer from "../countdown/container/image/image_timer.png";
import CountdownTimer from "../countdown/container/timer/CountdownTimer";
import { MoveCountdown } from "./container/move/moveCountdown";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Spacer } from "../container/spacer";
import Parallax from "react-rellax";
import { isMobile } from "react-device-detect"

//css
import '../countdown/container/style/styleCountdown_PC.css';
import '../countdown/container/style/styleCountdown_SP.css';

export const TitleCountdown = () => {
  const NOW_IN_MS = new Date(2023, 10, 11, 12, 0, 0).getTime();
  //const NOW_IN_MS = new Date(2023, 6, 12).getTime();
  const dateTimeAfterThreeDays = NOW_IN_MS;

  return(
    <div>
      {isMobile ?  <Spacer size={1000}/>  :  <Spacer size={400}/> }

      <div className="title">
        <div data-aos="fade-up" data-aos-offset="300" data-aos-once="true" className={isMobile ? "profileSub_I_S" : "profileSub_I_P" }>
          <ImageCountdown className={isMobile ? "countdown-Style-image_S" : "countdown-Style-image_P" } />
        </div>
        <div className={isMobile ? "countdown-Title-logo_S" : "countdown-Title-logo_P"}>
          <MoveCountdown />
        </div>
      </div>

      {isMobile ?  <Spacer size={0}/>  :  <Spacer size={400}/> }
      <div className="title">
        {/* <LogoCountdownday className={isMobile ? "countdownday-Title-logo_S" : "countdownday-Title-logo_P" }/> */}
        <img src={imageTimer} className={isMobile ? "imageTimer_S" : "imageTimer_P"} alt="timer"/>
        <CountdownTimer targetDate={dateTimeAfterThreeDays} />
      </div>
    </div>
  )};
