  import { Button } from "@mui/material";
  import axios from "axios";
  import React, { memo, useState, useContext, useEffect } from "react";
  import { User } from "../login/user";
  import { useNavigate } from "react-router-dom";
  import { isMobile } from "react-device-detect"
  import { ErrorModal } from "./container/error/error";

  //image_P
  import image_P_01 from "./container/image_P/image01.jpg"
  import image_P_02 from "./container/image_P/image02.jpg"
  import image_P_03 from "./container/image_P/image03.jpg"
  //image_S
  import image_S_01 from "./container/image_S/image01.jpg"
  import image_S_02 from "./container/image_S/image02.jpg"
  import image_S_03 from "./container/image_S/image03.jpg"

  // css
  import "../login/container/style/styleLogin.css";
  import "../login/container/style/styleLoginAll.css";
  import "../login/inputText/styleInputText_P.scss"
  import "../login/inputText/styleInputText_S.scss"
  import { AuthContext } from "../container/authContext";


  const images_P = [
    image_P_01,
    image_P_02,
    image_P_03,
  ];

  const images_S = [
    image_S_01,
    image_S_02,
    image_S_03,
  ];

  
  export const TitleLogin: React.FC = () => {
    const [password, setPassword] = useState("");
    const [randomImage_S, setRandomImage_S] = useState("");
    const [randomImage_P, setRandomImage_P] = useState("");
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const auth = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
      const randomIndex_S = Math.floor(Math.random() * images_S.length);
      setRandomImage_S(images_S[randomIndex_S]);
    }, []);

    useEffect(() => {
      const randomIndex_P = Math.floor(Math.random() * images_P.length);
      setRandomImage_P(images_P[randomIndex_P]);
    }, []);

    const onClick = () => {
      if (password === "KR1111") {
        auth?.setUserAuth(true);
        if (auth?.userAuth) {
          navigate("/rB4yG2jF6zV8W9A1e3T7");
        }
      } else {
        console.log("Before setPassword: ", password);
        setIsErrorModalOpen(true);
        setPassword("");
        console.log("After setPassword: ", password);
      }
    };
    return (
      <React.Fragment>
      {isErrorModalOpen && (
        <ErrorModal
          open={isErrorModalOpen}
          onClose={() => setIsErrorModalOpen(false)}
          errorMessage={
            <>
              パスワードが間違っています
              <br />
              もう一度入力してください
            </>
          }
        />
      )}
        {isMobile ?
          <div 
            className="title"
            style={{
              backgroundImage: `url(${randomImage_S})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
          <input
            type="text"
            id="password"
            placeholder="秘密のコードを入力（半角入力）"
            value={password}
            onChange={(e) => {
              const inputValue = e.target.value;
              const sanitizedInput = inputValue.replace(/[^\x20-\x7E]/g, '');
              setPassword(sanitizedInput);
            }}
          />
            <Button
              variant="contained"
              color="info"
              onClick={onClick}
              className="btn_S"
              >
              Login
            </Button>
          </div>
        : 
          <div 
            className="title"
            style={{
              backgroundImage: `url(${randomImage_P})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <input
              type="text"
              id="password"
              placeholder="秘密のコードを入力（半角入力）"
              value={password}
              onChange={(e) => {
                const inputValue = e.target.value;
                const sanitizedInput = inputValue.replace(/[^\x20-\x7E]/g, '');
                e.target.value = sanitizedInput;
                setPassword(sanitizedInput);
              }}
            />
            <Button
              variant="contained"
              color="info"
              onClick={onClick}
              className="btn_P"
              >
              Login
            </Button>
          </div>
        }
      </React.Fragment>
    );
  };
  