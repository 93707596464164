import ReactVivus from 'react-vivus';
import svg from "../logo/PC/risa_name_P.svg";

export const RisaNameP = () => {
  const handleRisaP = (obj:any) => {
    obj.el.classList.add("fill");
  };

  return (
    <div>
      <ReactVivus
        id="RisaNameP"
        option={{
          file: svg,
          type: 'delayed',
          animTimingFunction: 'EASE',
          duration: 180,
        }}
        callback={handleRisaP}
      />
    </div>
  );
};
