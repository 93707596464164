import ReactVivus from 'react-vivus';
import svgCount from "../logo/LogoCountdown.svg";
import "../style/styleCountdown.css";

export const MoveCountdown = () => {
  const countComplete = (obj:any) => {
    obj.el.classList.add("fill");
  };

  return (
    <div>
      <ReactVivus
        id= "Countdown"
        option={{
          file: svgCount,
          type: 'delayed',
          animTimingFunction: 'EASE',
          duration: 180,
        }}
        callback={countComplete}
      />
    </div>
  );
};
