import '../App.css';
import { TitleLogin } from "../login/TitleLogin";
import { AuthProvider } from "../container/authContext"

const SignIn = () => {

  return (
    <AuthProvider>
      <div className="App">
        <TitleLogin />
      </div>
    </AuthProvider>
  );
}

export default SignIn;