import DateTimeDisplay from "./DateTimeDisplay";
import { useCountdown } from "./useCountdown";
import { isMobile } from "react-device-detect"

// css
import "../style/styleCountdownTimer_P.css";
import "../style/styleCountdownTimer_S.css";

const ExpiredNotice = () => {
  return (
    <div className={isMobile ? "expired-notice_S" :  "expired-notice_P" }>
      <span>Thanks for coming</span>
      <h1>welcome to happy wedding</h1>
      <p>幸せな結婚式へようこそ</p>
    </div>
  );
};

const ShowCounter = ({ days, hours, minutes, seconds }: { days: number, hours: number, minutes: number, seconds: number }) => {
  return (
    <div className={isMobile ? "show-counter_S" :  "show-counter_P" }>
      <div className="countdown-day">11月11日12:00(挙式) まで</div>
      <a className="countdown-link">
        <DateTimeDisplay value={days} type={'日'} isDanger={days <= 3} />
        <DateTimeDisplay value={hours} type={'時間'} isDanger={false} />
        <DateTimeDisplay value={minutes} type={'分'} isDanger={false} />
        <DateTimeDisplay value={seconds} type={'秒'} isDanger={false} />
      </a>
    </div>
  );
};

const CountdownTimer = ({ targetDate }: { targetDate: number }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice />;
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};

export default CountdownTimer;
