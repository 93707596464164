import { isMobile } from "react-device-detect"

export const FileKazu = () => {
  return(
    <div className={isMobile ? "file_kazu_S" : "file_kazu_P" }>
      生年月日：1997年4月20日
      <br />
      血液型　：B型
      <br />
      身長　　：180cm
      <br />
      趣味　　：プログラミング
    </div>
  )}