import '../App.css';
import { isMobile } from "react-device-detect"
//読込テスト用
import ReactDOM from 'react-dom';
import {TitleMessage} from "../message/TitleMessage";
import {TitleProfile} from "../profile/TitleProfile";
import {TitleCountdown} from "../countdown/TitleCountdown";
import {TitleAccess} from "../access/TitleAccess";
import {TitleEnquete} from "../enquete/TitleEnquete";
import { TitleMain } from "../main/TitleMain";

//PCver
import imageWhite from "../form/image/wide/imageWhite.png";
import w_image01 from "../form/image/wide/w_image01.png";
import w_image02 from "../form/image/wide/w_image02.png";
import w_image03 from "../form/image/wide/w_image03.png";
import w_image04 from "../form/image/wide/w_image04.png";
import w_image05 from "../form/image/wide/w_image05.png";

import React, {useState, useEffect, useContext } from 'react';
import { AuthProvider } from "../container/authContext"
import { AuthContext } from "../container/authContext";
//import { Screens } from './container/ background';

import "../form/style/styleForm.css";

const Bridal: React.FC = () => {
  const auth = useContext(AuthContext);

  //表示がpcかスマホか
  {isMobile ? (
    // スマホ処理
    window.addEventListener("scroll", function() {
      let scroll = window.pageYOffset;
      if (scroll > 11300) {
        document.body.className="image05";
        document.body.style.backgroundColor = '#7fffd4';
      } else if (scroll > 7000) {
        document.body.className="image04";
        document.body.style.backgroundColor = '#ffffff';
      } else if (scroll > 5600) {
        document.body.className="image03";
        document.body.style.backgroundColor = '#7fffd4';
      } else if (scroll > 2300) {
        document.body.className="image02";
        document.body.style.backgroundColor = '#ffd700';
      } else if (scroll > 500) {
        document.body.className="image01";
        document.body.style.backgroundColor = '#FF4081';
      } else {
        document.body.className="imageWhite";
      }
    })
  ) : (
    // PC処理
    window.addEventListener("scroll", function() {
      let scroll = window.pageYOffset;
      if (scroll > 14700) {
        document.body.className="back";
        document.body.style.backgroundImage = `url(${w_image05})`
        document.body.style.backgroundColor = '#7fffd4';
      } else if (scroll > 8750) {
        document.body.className="back";
        document.body.style.backgroundImage = `url(${w_image04})`
        document.body.style.backgroundColor = '#ffffff';
      } else if (scroll > 6300) {
        document.body.className="back";
        document.body.style.backgroundImage = `url(${w_image03})`
        document.body.style.backgroundColor = '#7fffd4';
      } else if (scroll > 3100) {
        document.body.className="back";
        document.body.style.backgroundImage = `url(${w_image02})`
        document.body.style.backgroundColor = '#ffd700';
      } else if (scroll > 800) {
        document.body.className="back";
        document.body.style.backgroundImage = `url(${w_image01})`
        document.body.style.backgroundColor = '#FF4081';
      } else {
        document.body.className="back";
        document.body.style.backgroundImage = `url(${imageWhite})`
      }
    })
  )}

  return (
    <div>
      <div className="App">
        <header className="App-header">
          <TitleMain />
          <TitleMessage name={"画像が入りまし"}/>
          <TitleProfile />
          <TitleCountdown />
          <TitleAccess />
          <TitleEnquete />
          {/* <LogoMessage /> */}
        </header>
      </div>
    </div>
  );
}

export default Bridal;