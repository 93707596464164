import { isMobile } from "react-device-detect"

const MessageRSVP_S = () => {
  return(
    <div className="message_RSVP_S">
      <h3>PSVP</h3>
      <p>
        <br />
        郵送でのご案内に代わり
        <br />
        当招待状をお送りしております
        <br />
        <br />
        お手数ではございますが
        <br />
        出席情報のご登録をお願い申し上げます
        <br />
        <br />
        また当日のお食事のご用意にあたり
        <br />
        アレルギー等がある方は
        <br />
        アレルギー欄にご記入くださいますよう
        <br />
        お願い申し上げます
        <br />
        <br />
        2023.10.6(金)までにご返信をお願いいたします
      </p>
    </div>
  )}

    export {MessageRSVP_S};