import { isMobile } from "react-device-detect"

export const FileRisa = () => {
  return(
    <div className={isMobile ? "file_risa_S" : "file_risa_P" }>
      生年月日：1995年3月6日
      <br />
      血液型　：B型
      <br />
      身長　　：148cm
      <br />
      趣味　　：ランニング
    </div>
  )}