import { isMobile } from "react-device-detect"

export const MessageKazu = () => {
  return(
    <div className={isMobile ? "message_kazu_S" : "message_kazu_P" }>
      【メッセージ】
      <br />
      お世話になっているみなさんに
      <br />
      楽しんでもらうため
      <br />
      プログラマーとして会場をジャックします！
      <br />
      楽しみにしていてください！
      <br />
    </div>
  )}