import ReactVivus from 'react-vivus';
import svg from "../logo/LogoEnquete.svg";

export const MoveEnquete = () => {
  const handleProfileComplete = (obj:any) => {
    obj.el.classList.add("fill");
  };

  return (
    <div>
      <ReactVivus
        id="EnqueteLogo"
        option={{
          file: svg,
          type: 'delayed',
          animTimingFunction: 'EASE',
          duration: 180,
        }}
        callback={handleProfileComplete}
      />
    </div>
  );
};
