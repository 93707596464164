import imageReception from "../image/reception.png";
import { isMobile } from "react-device-detect"

export const DriveMap = () => {
  return(
      <div className="receptionMap">
        <div className={isMobile ? "card_sub_S" : "card_sub_P"}>
          <div className={isMobile ? "content_S" : "content_P"}>
            <h3>お車でお越しの方へ</h3>
            <a href="https://www.hgh.co.jp/access/carport.html">駐車場の確認はこちら</a>
            <p>当日は駐車場が混雑することが予想されております<br />できるだけ公共交通機関をご利用くださいますよう<br />お願いいたします</p>
          </div>
        </div>
      </div>
)};