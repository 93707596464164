import { isMobile } from "react-device-detect"

export const MessageRisa = () => {
  return(
    <div className={isMobile ? "message_risa_S" : "message_risa_P" }>
      【メッセージ】
      <br />
      貴重な１日をぜひ
      <br />
      私と一緒に食べて飲んで相手してください
      <br />
      当日は二人の大好きな事を沢山詰め込みました！
      <br />
      皆さんに会える事が楽しみです！
    </div>
  )}