import imageCeremony from "../image/ceremony.png";
import { isMobile } from "react-device-detect"
import floor6F from "../image/floor6F.png";

export const CeremonyMap = () => {
  return(
      <div className="ceremonyMap">
        <div className={isMobile ? "card_S" : "card_P"}>
          <div className={isMobile ? "content_S" : "content_P"}>
            <h2>挙式</h2>
            <h3>ホテルグランヴィア広島</h3>
            <p>〒732-0822 広島県広島市南区松原町1-5 <br />TEL：082-262-1111</p>
            <a href="https://www.hgh.co.jp/">公式サイト
            </a>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1776.459025406961!2d132.47437528789993!3d34.39903088264507!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x355aed5fd4d8b5b7%3A0x1e092189a6415977!2z44Ob44OG44Or44Kw44Op44Oz44O044Kj44Ki5bqD5bO2!5e0!3m2!1sja!2sjp!4v1685542312372!5m2!1sja!2sjp"></iframe>
            {isMobile ? null : <img src={imageCeremony} className="weddingMap-image_P" alt="ceremony"/>}
          </div>
          <div className={isMobile ? "content_sub_S" : "content_sub_P"}>
            <h4>場所：6F　チャペルルナルシア</h4>
            <p>待合までお越しください</p>
            <img src={floor6F} className={isMobile ? "Floor6F_S" : "Floor6F_P"} alt="fllor6F"/>
          </div>
        </div>
      </div>
)};