import { BrowserRouter, Routes, Route } from "react-router-dom";
import Signin from "../src/form/sign-in";
import Top from "../src/form/bridal";
import {AuthProvider} from "../src/container/authContext";

const App = () => {

  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path={`/`} element={<Signin />} />
          <Route path={`/rB4yG2jF6zV8W9A1e3T7`} element={<Top />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
